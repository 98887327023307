@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts starting */
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.eot");
  src: url("./assets/fonts/Poppins-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Light.woff") format("woff"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.eot");
  src: url("./assets/fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Bold.woff") format("woff"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-ExtraBold.eot");
  src: url("./assets/fonts/Poppins-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-ExtraBold.woff") format("woff"),
    url("./assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.eot");
  src: url("./assets/fonts/Poppins-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Medium.woff") format("woff"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.eot");
  src: url("./assets/fonts/Poppins-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.eot");
  src: url("./assets/fonts/Poppins-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Thin.eot");
  src: url("./assets/fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Poppins-Thin.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Thin.woff") format("woff"),
    url("./assets/fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* fonts ending */
html,
body {
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #ffffff;
  background: linear-gradient(311.99deg, #2c3345 74.23%, #323045 123.76%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td:first-child {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
td:last-child {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #e4e4e7;
  border-radius: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #a1a1aa;
  border-radius: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #888;
}

* {
  font-family: "Poppins";
}

.container {
  max-width: 1513px;
  margin: auto;
}

.text-primary {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #ffffff;
}

.text-secondary {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /*  color: #7E8898;*/
  color: rgba(165, 174, 188, 1);
}

.card {
  transition: all 0.5s;
}

.animation {
  transition: all 0.5s;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card-animate {
  transition: all 0.1s linear;
}

.card-animate:hover {
  background-color: #41485c;
  box-shadow: 0px 0px 0px 3px #41485c;
}

/* .card-animate:active {
  border: 1px solid #FFD74A;
  transform: scale(0.98);
} */

.header-gradient {
  background: linear-gradient(180deg, #2c3345 50%, rgba(44, 51, 69, 0.6) 100%);
  backdrop-filter: blur(12px);
}

.chart-wrapper canvas {
  width: 100% !important;
  height: 300px !important;
}

.scroll-to-top {
  /* scroll-padding-top: 0px; */
  scroll-behavior: smooth;
}

.slide-in {
  transform: translateX(0) !important;
  transition: all 0.5s ease-in-out !important;
}

.slide-out {
  transform: translateX(-100%) !important;
  transition: all 0.5s ease-in-out !important;
}

.tabOuterDiv {
  padding: 12px 0px 12px 12px;
  border-radius: 16px 0px 0px 16px;
  position: relative;
}

.tabOuterDiv:hover {
  cursor: pointer;
  background-color: #2c3345;
}
.tabOuterDiv:hover .nonActiveTab::after {
  content: " ";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
  width: 4px;
  height: 32px;
  background-color: #41485c;
}
.tabOuterDiv .activeTab::after {
  content: " ";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
  width: 4px;
  height: 32px;
  background-color: #ffd74a;
}

.activeTab {
  color: #ffd74a !important;
  align-items: center;
  transition: transform 0.2s;
}

.activeTab svg path {
  fill: #ffd74a;
}

.tabOuterDiv:hover .activeTab svg {
  transform: scale(1.25);
  transition: all 0.1s ease-in-out;
}

.nonActiveTab {
  align-items: center;
  transition: transform 0.2s;
}

.nonActiveTab svg path {
  /*  fill: #7E8898;*/
  color: rgba(165, 174, 188, 1);
}

.tabOuterDiv:hover .nonActiveTab svg path {
  fill: #ffd74a;
}

.tabOuterDiv:hover .nonActiveTab svg {
  transform: scale(1.25);
  transition: all 0.1s ease-in-out;
}

.tabOuterDiv:hover .nonActiveTab {
  color: #ffe766 !important;
}

.manageTabOuterDiv {
  padding: 12px 0px 12px 12px;
  border-radius: 16px 0px 0px 16px;
  position: relative;
  cursor: pointer;
}

/*.manageTabOuterDiv:hover {
  cursor: pointer;
  background-color: #2c3345;
}*/

.manageTabOuterDiv .activeTab::after {
  content: " ";
  position: absolute;
  bottom: -4px;
  left: 20%;
  transform: translate(0px, -50%);
  width: 80%;
  height: 4px;
  background-color: #ffd74a;
}

.manageTabOuterDiv .activeTab {
  color: #ffffff !important;
  align-items: center;
  transition: transform 0.2s;
}

.manageTabOuterDiv .activeTab svg path {
  fill: #ffd74a;
}

.manageTabOuterDiv:hover .activeTab svg {
  transform: scale(1.25);
  transition: all 0.1s ease-in-out;
}

.manageTabOuterDiv .nonActiveTab {
  align-items: center;
  transition: transform 0.2s;
}

.manageTabOuterDiv .nonActiveTab svg path {
  /*  fill: #7E8898;*/
  color: rgba(165, 174, 188, 1);
}

.manageTabOuterDiv:hover .nonActiveTab svg path {
  fill: #ffd74a;
}

.manageTabOuterDiv:hover .nonActiveTab svg {
  transform: scale(1.25);
  transition: all 0.1s ease-in-out;
}

.manageTabOuterDiv:hover .nonActiveTab {
  color: #ffffff !important;
}



.bd {
  border: 2px solid red;
}

.icon-wrapp {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.red path {
  fill: #ed4650;
}

.yellow path {
  fill: #ffe766;
}

.green path {
  fill: #6be261;
}
.bad:hover path {
  fill: #ed4650;
}

.average:hover path {
  fill: #ffe766;
}

.good:hover path {
  fill: #6be261;
}

.ratingIcon:hover {
  cursor: pointer;
  transform: scale(1.15);
  transition: all 0.1s ease-in-out;
}
/*input{
  color: #ffffff !important;
}*/
.table-head {
  position: relative;
}
.table-head::after {
  content: " ";
  position: absolute;
  /*bottom: 8px;*/
  bottom: 0;
  left: -10px;
  right: -10px;
  width: calc(100% + 20px);
  height: 1px;
  background-color: #434a5d;
}
.table-head-new::after {
  content: " ";
  position: absolute;
  /*bottom: 8px;*/
  bottom: 0;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 1px;
  background-color: #434a5d;
}
.table-head.thead-border::before {
  content: " ";
  position: absolute;
  /*bottom: 8px;*/
  top: 0;
  left: -10px;
  right: -10px;
  width: calc(100% + 20px);
  height: 1px;
  background-color: #434a5d;
}
.table-head th {
  /*padding-bottom: 28px;*/
  white-space: nowrap;
}

.line-head {
  position: relative;
}

.line-head::after {
  /*content: " ";*/
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #596073;
}

.customize_setting_model {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.model_header {
  height: 78px;
  border-bottom: 1px solid #596073;
  padding: 0 24px;
}

.settings_content_wrapper {
  flex: 1;
  display: flex;
  max-height: calc(100% - 78px);
  align-items: stretch;
}

.setting_sidebar {
  padding: 24px 0 24px;
  border-right: 1px solid #596073;
}

.manage_tab {
  padding: 24px 0 0px;
  border-bottom: 1px solid #596073;
}

.right_model_details {
  display: flex;
  flex: 1;
  padding: 16px 32px;
}

.manage-right_model_details {
  display: flex;
  flex: 1;
  padding: 0 0 24px 1px;
}

.manage_inner_right_model_details {
  display: flex;
  flex: 1;
}

.manage_inner_right_model_details > div {
  position: initial !important;
  height: 100%;
  width: 100%;
}

.manage-right_model_details > div {
  position: initial !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tab_section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}

.tab_section .line-head {
  flex: 1;
  display: flex;
  flex-direction: column;
/*  height: 100%;*/
  padding: 0 0 24px;
  height: calc(100% - 81px);
}

.tab_section .line-head .manage_inner_right_model_details {
  overflow: hidden;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  height: 100%;
}

.tab_section .line-head .showDatePanel {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.showDatePanel .panelContent {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.showDatePanel .panelContent .detailsSection {
  flex: 1 1;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 4px 0 0;
}
.right_model_details > div {
  position: initial !important;
  height: 100%;
  width: 100%;
}

.ManageServicesBox {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ManageServicesBox .scrollable_data {
  position: relative;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 20px;
}

.ManageServicesBox .scrollable_data .custom_scroll_hide {
  overflow: initial;
}

.ManageServicesBox .scrollable_data .custom_scroll_hide table {
  width: 100%;
}

.ManageServicesBox .scrollable_data .custom_scroll_hide table thead {
  position: sticky;
  top: 0;
  /*  background: #363e4f;*/
  z-index: 1;
}

.searchBox {
  border-radius: 16px;
  display: flex;
  position: relative;
}

.searchBox .searchIcon {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 16px;
}

.searchBox .searchInput {
  width: 100%;
  background: transparent;
  border-radius: inherit;
  border: 1px solid #596073;
  padding: 14px 16px 14px 52px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-style: italic;
  box-shadow: none;
  outline: none;
}

.searchBox .searchInput:focus {
  border: 1px solid white;
  color: white;
  box-shadow: none;
}

.searchBox .searchInput::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #A5AEBC;
}
.searchBox .searchInput::-moz-placeholder {
  /* Firefox 19+ */
  color: #A5AEBC;
}
.searchBox .searchInput:-ms-input-placeholder {
  /* IE 10+ */
  color: #A5AEBC;
}
.searchBox .searchInput:-moz-placeholder {
  /* Firefox 18- */
  color: #A5AEBC;
}

.AddServiceModel {
  text-align: left;
}

.header_popup {
  border-bottom: 1px solid #434a5d;
}

.service_heading {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #a5aebc;
  font-weight: 500;
}

.service_text {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  color: #7e8898;
  font-weight: 400;
}

.sidebar_nav_con {
  height: calc(100vh - 264px);
}

.step_heading {
  color: #a5aabc;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.serviceBox small {
  font-size: 14px;
  color: #8a8a98;
  line-height: 20px;
  font-weight: 400;
  font-style: italic;
}

.serviceAction {
  width: 80px;
}

.popupFooter {
  margin: auto 0 0;
}

button.btnHover:disabled {
  background: #596073 !important;
  color: #a5aebc !important;
  cursor: not-allowed;
}

.optionalText {
  color: #a5aebc;
}

.lineHeight {
  line-height: 20px;
}

.headingBox button,
.lineHeight21 button {
  line-height: 21px;
}

.font_12px .manageService {
  font-size: 12px;
}
.serviceAction .editService {
  color: #ffe24b;
  text-decoration: underline;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.tb-sd-rs-is {
  margin-top: 0px;
}
/* @media screen and (max-width: 1542px){ */
/* .column-gap-78 { */
/* column-gap: 78px !important; */
/* justify-content: center !important; */
/* } */
/* } */

.borderOutline {
  border: 1px solid rgba(89, 96, 115, 1);
}

@media screen and (max-width: 1279px) {
  .column-gap-78 {
    column-gap: auto !important;
  }
}

.submitted_services_tabel_scroll {
  height: calc(100% - 104px) !important;
}

.sideModal_nav {
  height: calc(100% - 81px);
}

.customize_submission_model {
  overflow: hidden;
  padding-top: 0;
}

.customize_submission_model .model_width {
  display: flex;
  flex-direction: column;
}

.customize_submission_model .model_header {
  position: initial;
}

.customize_submission_model .table_wrapper {
  overflow: hidden;
  flex: 1;
  overflow-y: auto;
}

.customize_submission_model .table_wrapper::-webkit-scrollbar {
  display: none;
}

.customize_submission_model .table_wrapper .table-auto {
  position: relative;
}

.customize_submission_model .table_wrapper .table-auto .table-head {
  position: sticky;
  top: 0;
  background-color: rgb(54 61 80 / var(--tw-bg-opacity));
  z-index: 9;
}

.service_layout .customize_sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.service_layout .customize_sidebar .main_sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.customize_sidebar .main_sidebar .main_sidebar_inner {
  width: 100%;
  flex: 1;
  height: 100%;
}

.customize_sidebar .main_sidebar .main_sidebar_inner .sidebar_nav_con {
  height: 100%;
}
/* 
.main_sidebar
  .main_sidebar_inner
  .sidebar_nav_coh-[378px]
  3xl:h-[325px]
  4xl:h-[270px]
  6xl:h-[378px]
  animation
  flex
  flex-col
  gap-[8px]
  mt-[auto]
  tb-sd-rs-is
  desktop_heightn
  .desktop_height {
  margin-top: auto;
  height: auto;
} */

@media (min-width: 1920px) {
  .container {
    max-width: 95%;
  }
}

@media (min-width: 1280px) {
  .service_layout {
    padding: 0 !important;
    overflow: initial;
    max-height: initial;
    overflow: hidden;
    height: 100vh;
    align-items: stretch;
  }

  .service_layout .customize_sidebar {
    position: initial;
    overflow: inherit;
  }

  /*.service_layout .customize_sidebar .main_sidebar {
    height: calc(100vh - 246px);
  }

  .service_layout .customize_sidebar .scrollbar-hide .sidebar_nav_con {
    height: auto;
  }*/

  .service_layout
    .customize_sidebar
    .scrollbar-hide
    .sidebar_nav_con
    .custom_main_height {
    max-height: initial;
    height: auto;
  }

  /*.service_layout .customize_sidebar .scrollbar-hide .sidebar_nav_con .desktop_height {
    height: auto;
  }*/

  .service_layout .right_panel {
    flex: 1;
    box-sizing: border-box;
    /* max-width: initial !important; */
    width: calc(100% - 450px);
    padding-bottom: 0px;
    justify-content: flex-start;
    /*height: 100vh;*/
    /*min-height: 870px;*/
    overflow: hidden;
    overflow-y: auto;
    padding-top: 0;
    justify-content: stretch;
  }

  .service_layout .right_panel::-webkit-scrollbar {
    display: none;
  }

  .service_layout .right_panel .card-section {
    height: auto;
    max-height: initial;
    flex: 1;
  }

  .service_layout .right_panel .card-section.without_sticky_header {
    /*    height: calc(870px - 290px);*/
  }

  .service_layout .right_panel .card-section.without_sticky_header .cards_wrap {
    height: calc(100vh - 306px);
  }

  .service_layout .right_panel .card-section .cards_wrap {
    max-height: initial;
    height: 100%;
    padding-bottom: 0;
    height: calc(100vh - 157px);
  }

  .service_layout .right_panel .card-section .cards_wrap.inner_space {
    /*padding: 0 0 30px;*/
    height: calc(100vh - 259px);
  }
  .service_layout
    .right_panel
    .card-section
    .cards_wrap.inner_space.home_module {
    /*padding: 0 0 30px;*/
    height: calc(100vh - 180px);
  }
  .service_layout .right_panel .card-section .cards_wrap.bottom_space {
    height: calc(100vh - 163px);
  }
  .service_layout .right_panel .card-section .cards_wrap.double_space {
    height: calc(100vh - 311px);
  }
  .service_layout .right_panel .card-section .cards_wrap.double_space_2 {
    height: calc(100vh - 488px);
  }

  .service_layout .right_panel .card-section .overflow-x-auto.scrollbar-hide {
    overflow: initial;
  }

  .service_layout .right_panel .card-section .table-head {
    position: sticky;
    top: 0;
    background-color: rgb(54 61 80 / var(--tw-bg-opacity));
    z-index: 1;
  }

  .service_layout .right_panel .cards_wrapper {
    max-height: initial;
    justify-content: flex-start;
  }
}

.imgWrapper {
  padding: 30px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.imgWrapper .boxImg {
  margin: 0 0 20px;
}

.imgWrapper .boxHeading {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: #ffffff;
}

.imgWrapper .boxText {
  font-size: 16px;
  line-height: 24px;
  color: #a5aebc;
  margin: 0 0 20px;
}

.imgWrapper .boxText .date {
  color: #ffffff;
  font-weight: 500;
}

.imgWrapper button {
  line-height: 19px;
}

.inline_text {
  white-space: nowrap !important;
}

.custom_btn {
  display: inline-block;
  padding: 8px 20px;
  background-color: #f6dc44;
  text-align: center;
  color: #323045;
  font-weight: 600;
  border-radius: 8px;
}

.custom_text {
  color: #a5aebc;
  font-size: 16px;
}

.btnHover:hover {
  transition: 0.2s all;
  transform: scale(1.05);
}

.card_img {
  object-fit: contain;
  /*  margin: auto;*/
}

.rating_wrapper {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rating_wrapper.false .active {
  display: none;
}

.rating_wrapper.false:hover .inactive {
  display: none;
}
.rating_wrapper.false:hover .active {
  display: block;
  transition: 0.2s all;
  transform: scale(1.05);
}

.rating_wrapper.active .active {
  display: block;
}
.rating_wrapper.active .inactive {
  display: none;
}

.rating_wrapper.active:hover .inactive {
  display: none;
}
.rating_wrapper.active:hover .active {
  display: block;
}

.face {
  padding: 2px 2px 2px 0px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  /*  background: rgba(255, 255, 255, 0.2);*/
}

.face.false {
  background: transparent !important;
}

.face.ratingIcon {
  padding: 2px 2px 2px 0px;
}

.face.green_face,
.face.good {
  background: rgba(107, 226, 97, 0.2);
}

.face.yellow_face,
.face.average {
  background: rgb(255, 231, 102, 0.2);
}

.face.red_face,
.face.bad {
  background: rgba(237, 70, 80, 0.2);
}

.flex_card {
  flex: 1;
}

.sorting_btn_wrapper {
  line-height: 21px;
  font-weight: 400;
  padding: 12px 8px 12px 12px !important;
  border-color: transparent !important;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.sorting_btn_wrapper:hover,
.sorting_btn_wrapper:focus {
  background-color: #434a5d;
}

.sorting_btn_wrapper span {
  transition: color 0.3s ease;
  width: 100%;
  gap: 4px;
  justify-content: space-between;
}

.sorting_btn_wrapper:hover span,
.sorting_btn_wrapper:focus span {
  color: #ffffff !important;
}

.sortingicon {
  transition: opacity 0.3s;
}

.sorting_btn_wrapper:hover .sortingicon,
.sorting_btn_wrapper:focus .sortingicon {
  opacity: 1;
}

.sorting_btn_wrapper_service {
  line-height: 21px;
  font-weight: 400;
  padding: 12px 8px 12px 12px !important;
  border-color: transparent !important;
  width: calc(100% + 20px);
  text-align: center;
  display: flex;
  align-items: center;
}

.sorting_btn_wrapper_service:hover,
.sorting_btn_wrapper_service:focus {
  background-color: #434a5d;
}

.sorting_btn_wrapper_service span {
  transition: color 0.3s ease;
  width: 100%;
  gap: 4px;
  justify-content: space-between;
}

.sorting_btn_wrapper_service:hover span,
.sorting_btn_wrapper_service:focus span {
  color: #ffffff !important;
}

.sortingicon {
  transition: opacity 0.3s;
}

.sorting_btn_wrapper_service:hover .sortingicon,
.sorting_btn_wrapper_service:focus .sortingicon {
  opacity: 1;
}

.sortingicon {
  opacity: 0;
}

.btn_text {
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 1420px) {
  .sortingicon {
    display: none !important;
  }
}

.MonthPickerWrap.onlyMonth .react-datepicker-wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  opacity: 0;
}

.calendarWrap .calendarVal {
  background: transparent;
  border: 1px solid #596073;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  padding: 12px 16px 12px 16px;
  width: 100%;
  border-radius: 16px;
  color: #ffffff;
  padding-left: 45px;
  text-align: left;
  height: 48px;
}

.calendarWrap .calChev {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
}

.calendarWrap .calChev .upBtn,
.calendarWrap .calChev .downBtn {
  cursor: pointer;
}
.calendarWrap .calChev .upBtn {
  transform: rotate(180deg);
}

.MonthPickerWrap .react-datepicker__input-container input {
  background: transparent;
  border: 1px solid #596073;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  padding: 12px 16px 12px 16px;
  width: 100%;
  border-radius: 16px;
  color: #ffffff;
}

.MonthPickerWrap .react-datepicker {
  background: #293043;
  color: #ffffff;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  border-radius: 16px;
  font-family: inherit;
}

.MonthPickerWrap .react-datepicker__navigation--previous {
  right: 36px !important;
  top: 12px;
  left: initial;
}

.MonthPickerWrap .react-datepicker__navigation--next {
  top: 12px;
}

.MonthPickerWrap .react-datepicker__day-names {
  margin-top: 4px;
}

.MonthPickerWrap .react-datepicker__header.react-datepicker-year-header,
.MonthPickerWrap .react-datepicker__header {
  background: #293043;
  color: #ffffff;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px !important;
  padding: 16px;
  text-align: left;
}

.date-custome-picker .react-datepicker__header {
  border: none !important;
}

.react-datepicker, .react-datepicker__header {
  border-color: #596073 !important;
}

.month-picker-container .react-datepicker {
  width: 100%;
}

.month-picker-container .react-datepicker__month-container {
  width: 100%;
}react-datepicker__month-container

.MonthPickerWrap .react-datepicker__month {
  display: flex;
  flex-direction: column;
}

.month-picker-container .react-datepicker__month {
  height: 184px;
  overflow-y: auto;
}

.month-picker-container .react-datepicker__month::-webkit-scrollbar {
  display: none;
}

.MonthPickerWrap .react-datepicker__month .react-datepicker__month-wrapper {
  display: flex;
  flex-direction: column;
}

.MonthPickerWrap
  .react-datepicker__month
  .react-datepicker__month-wrapper
  .react-datepicker__month-text {
  width: 100%;
  margin: 0 0 6px;
  padding: 10px 8px;
  text-align: left;
  border-radius: 12px;
}

.MonthPickerWrap
  .react-datepicker__month
  .react-datepicker__month-wrapper:last-child
  .react-datepicker__month-text:last-child {
  margin: 0;
}

.MonthPickerWrap
  .react-datepicker__month
  .react-datepicker__month-wrapper
  .react-datepicker__month-text--keyboard-selected {
  background-color: #363d50;
  border: 1px solid #ffd74a;
  border-radius: 12px;
  color: white;
}

.MonthPickerWrap
  .react-datepicker__month
  .react-datepicker__month-wrapper
  .react-datepicker__month-text:hover {
  background-color: #363d50;
}

.customDragDrop {
  position: relative;
}

.customDragDrop:hover .drop-container {
  background-color: #434A5D;
}

.customDragDrop:active .drop-container {
  border-color: #FFD74A !important;
  background-color: #434A5D;
}

.customDragDrop label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: initial;
  opacity: 0;
}

.DragDropContainer {
  height: 156px;
  width: 100%;
  border-width: 1px;
  border-style: dashed;
  border-color: #596073;
  border-radius: 16px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.customDragDrop:hover .DragDropContainer {
  border-color: #A5AEBC;
  background-color: #434A5D;
}

.customDragDrop:active .DragDropContainer {
  border-color: #FFD74A !important;
  background-color: #434A5D;
}

.ManageDragDropContainer {
  height: 156px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.DragDropContainer .DragDropImg {
}

.DragDropContainer .DragDropHeading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 16px 0 0;
}

.DragDropContainer .fileText {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #a5aebc;
}

.DragDropContainer .selectText {
  text-decoration: underline;
  color: #ffd74a;
}

.ManageDragDropContainer .DragDropImg {
}

.ManageDragDropContainer .DragDropHeading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 16px 0 0;
}

.ManageDragDropContainer .fileText {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #a5aebc;
}

.ManageDragDropContainer .selectText {
  text-decoration: underline;
  color: #ffd74a;
}

.dateRangeBox {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.dateRangeBox .MonthPickerWrap {
  width: calc(50% - 6px);
}

.dateRangeBox .MonthPickerWrap .react-datepicker__header {
  padding: 16px 16px 8px;
}

.dateRangeBox
  .MonthPickerWrap
  .react-datepicker__header
  .react-datepicker__day-name {
  color: #a5aebc;
}

.dateRangeBox .MonthPickerWrap .react-datepicker__day {
  color: #ffffff;
  border: 1px solid transparent;
}
.dateRangeBox
  .MonthPickerWrap
  .react-datepicker__day.react-datepicker__day--outside-month {
  color: #a5aebc;
}

.dateRangeBox
  .MonthPickerWrap
  .react-datepicker__day.react-datepicker__day--selected {
  border-radius: 8px;
  background-color: #363d50;
  border: 1px solid #ffd74a;
  color: #ffd74a !important;
  font-weight: initial;
}

.dateRangeBox .MonthPickerWrap .react-datepicker__day:hover {
  border-radius: 8px;
  background-color: #363d50;
  border: 1px solid #ffd74a;
  font-weight: initial;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 8px !important;
  }

.dateRangeBox .MonthPickerWrap .react-datepicker__current-month {
  text-align: left;
  color: #ffffff;
}

.custom_btn_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_btn_wrap .icon-wrapp {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleBar {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 8px;
}

.anotherServiceLink {
  font-size: 14px;
  line-height: 24px;
  color: #ffd74a;
  text-decoration: underline;
  font-weight: 400;
  margin: 20px 0 0;
}

.calendarWrap {
  position: relative;
}

.calendarWrap .calendarIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 10px;
}

.btn_td button {
  margin: 0;
  min-width: 104px;
  text-align: center;
  justify-content: center;
}

.calendarWrap .react-datepicker-wrapper input {
  padding-left: 45px;
  cursor: pointer;
}
.custom_btn_wrap .icon-wrapp svg path {
  fill: #ffd74a;
}

.serviceSection {
  border: 1px solid #596073;
}

.serviceRow:not(:first-of-type) {
  margin: 16px 0 0;
  padding: 16px 0 0;
  border-top: 1px solid #596073;
}

.serviceRow .serviceName {
  line-height: 21px;
  color: #fff;
}

.memberLink {
  color: #ffd74a;
  text-decoration: underline;
}

.fontBold {
  font-weight: 700;
}

.chooseMemebr {
  flex: 1;
}

.SetupServiceModel {
  width: 100%;
}
.selectTypeWrap {
  position: relative;
}

.selectType select {
  border: 1px solid #596073;
  background: transparent;
  padding: 12px 16px;
  border-radius: 16px;
  width: 183px;
}

.selectTypeWrap .downChevron {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.tabWrap {
  border-bottom: 1px solid #41485c;
}

.tabWrap .tabBtn {
  color: #a5aebc;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  height: 32px;
  border-bottom: 4px solid transparent;
}

.tabWrap .tabBtn.active {
  border-color: #ffd74a;
  color: #ffd74a;
}

.textSection {
  margin: 0 0 24px;
}

.textHeading {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 2px;
}

.textCard {
  font-size: 14px;
  line-height: 21px;
  color: #a5aebc;
  font-weight: 400;
}

.textBoxCheck {
  position: relative;
}

.textBoxCheck:before {
}

.uploadHeading {
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 20px;
}

.contractRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px;
  border-top: 1px solid #434a5d;
  border-bottom: 1px solid #434a5d;
  margin: 0 0 15px;
  text-align: left;
}

.contractName {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 5px;
}
.uploadText {
  font-size: 14px;
  line-height: 21px;
  color: #a5aebc;
  text-align: left;
  margin: 0 0 24px;
}

.btnText {
  color: #ffd74a;
}

.calenderWrap .yearsShow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
}

.yearsShow .year {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.yearAction .calChev {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.yearAction .calChev .prevBtn,
.yearAction .calChev .nextBtn {
  cursor: pointer;
}
.yearAction .calChev .prevBtn {
  transform: rotate(90deg);
}

.yearAction .calChev .nextBtn {
  transform: rotate(270deg);
}

.downloadLink {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 21px;
  color: #ffe24b;
/*  margin: 0 0 12px;*/
}

.monthWrap {
  margin: 0 -24px 20px;
  padding: 0 24px;
  max-height: 280px;
  border-bottom: 1px solid #434a5d;
  overflow: hidden;
  overflow-y: auto;
}

.monthWrap::-webkit-scrollbar {
  display: none;
}

.monthRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px;
  border-top: 1px solid #434a5d;
  margin: 0 0 15px;
  text-align: left;
}
.monthRow:nth-last-of-type(1) {
  border-bottom: 1px solid #434a5d;
}

.manage-service-wrapper table {
  width: 100%;
}

.fileName {
  color: #a5aebc;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 21px;
  font-style: italic;
}

.companyImage {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.highlightedBtn {
  border: 1px solid #ffd74a;
  border-radius: 12px;
  padding: 5px 10px;
}

.border-color {
  border-bottom-color: #596073;
}

.custom-boxshadow {
  box-shadow: 2px 22px 24px -10px #1135b503;
}

.request-type-case::first-letter {
  text-transform: uppercase;
}

.popup_scrollbody {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}

.Toastify__toast-icon {  
  width: 32px !important;
}

.Toastify__toast-container {
  width: max-content !important;
}

.manage-divider {
  border-bottom: 1px solid #596073;
}

.px-thirty_two {
  padding-left: 32px;
  padding-right: 32px;
}

.orgSetting-time .react-datepicker__time-container .react-datepicker__time {
  background-color: #293043 !important;
}

.orgSetting-time .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #fff !important;
}
.orgSetting-time .react-datepicker__time-container {
  width: 268px !important;
}
.orgSetting-time .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
  padding: 8px;
}
.orgSetting-time .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 10px 8px;
  height: auto;
  text-align: left;
  border-radius: 12px;
  margin-bottom: 4px;
  border: 1px solid transparent;
}
.orgSetting-time .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:last-child {
  margin-bottom: 0px;
}
.orgSetting-time .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #434A5D !important;
}
.orgSetting-time .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:active {
  background-color: #434A5D !important;
  border: 1px solid #FFD74A;
}
.orgSetting-time .MonthPickerWrap .react-datepicker__input-container input {
  padding: 12px 16px 12px 48px;
  cursor: pointer;
}
.orgSetting-day .MonthPickerWrap .react-datepicker__input-container input {
  padding: 12px 16px 12px 48px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
  display: none;
}

.react-datepicker__triangle {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.timeZoneWrapper {
  width: 39%;
}

.timeZoneWrapper .css-b62m3t-container .css-13cymwt-control, 
.timeZoneWrapper .css-b62m3t-container .css-t3ipsp-control {
  background: transparent;
  border: 1px solid #596073;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  padding: 5px 16px 4px 16px;
  width: 100%;
  border-radius: 16px;
  color: #ffffff;
  cursor: pointer;
}

/*.timeZoneWrapper .css-1xc3v61-indicatorContainer, 
.timeZoneWrapper .css-151sz6c-indicatorContainer {
  color: #ffffff;
}
.timeZoneWrapper .css-151sz6c-indicatorContainer:hover {
  color: #ffffff;
}*/

.timeZoneWrapper .css-b62m3t-container .css-13cymwt-control .css-1dimb5e-singleValue, .timeZoneWrapper .css-b62m3t-container .css-t3ipsp-control .css-1dimb5e-singleValue {
  color: #ffffff;
}

.timeZoneWrapper .css-1nmdiq5-menu {
  background: #293043;
  border: 1px solid #596073;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  padding: 4px 8px;
  width: 100%;
  border-radius: 16px;
  color: #ffffff;
}

.timeZoneWrapper .css-1nmdiq5-menu::-webkit-scrollbar {
  display: none;
}

/*.timeZoneWrapper .css-1nmdiq5-menu .css-10wo9uf-option:hover  {
  background-color: #2684FF;
  -webkit-tap-highlight-color: initial;
  color: #000;
}*/

.timeZoneWrapper .css-qr46ko::-webkit-scrollbar {
  display: none;
}

.timeZoneWrapper .css-qr46ko div {
  padding: 10px 8px;
  margin-bottom: 4px;
  border-radius: 12px;
  border: 1px solid transparent;
}

.timeZoneWrapper .css-qr46ko div:last-child {
  margin-bottom: 0px;
}

.timeZoneWrapper .css-qr46ko div:hover {
  background-color: #434A5D;
  -webkit-tap-highlight-color: initial;
  color: #ffffff;
  cursor: pointer;
}

.timeZoneWrapper .css-qr46ko div:active {
  border: 1px solid #ffd74a;
}

.timeZoneWrapper .css-qr46ko [aria-selected="true"] {
  background-color: #434A5D !important;
  border: 1px solid #ffd74a;
  -webkit-tap-highlight-color: initial;
  color: #ffffff;
}

.timeZoneWrapper .css-qr46ko div {
  background-color: transparent;
  color: #ffffff;
}
.css-1cfo1cf>input {
  color: white !important;
}
.css-1cfo1cf>input:focus {
  --tw-ring-shadow: none !important
}
.css-19bb58m>input:focus {
  --tw-ring-shadow: none !important
}

.css-13cymwt-control .css-1nmdiq5-menu {
  background-color: #f0f0f0; /* Set your desired background color here */
}

.css-13cymwt-control, .css-t3ipsp-control {
  box-shadow: none !important;
}

.css-13cymwt-control:hover, .css-t3ipsp-control:hover {
  border-color: white !important;
}

.react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #434A5D !important;
  border: 1px solid #FFD74A !important;
}

/* For WebKit browsers (Chrome, Safari) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
input:autofill,
input:autofill:hover, 
input:autofill:focus {
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-text-fill-color: #A5AEBC;
}

/* For Edge */
input:-ms-input-placeholder {
  background-color: transparent !important;
}

.two-line-ellipsis {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Remove default outline on focus */
input[type="checkbox"]:focus {
  outline: none; /* Remove the outline */
  box-shadow: none;
}

input[type='checkbox']:checked {
  background-image: url("./assets/images/checkbox2.png");
}

.focus-element {
  width: 0px;
  height: 0px;
}

.focus-element:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
